<template>
  <main class="page">
    <Wrapper boxed>
      <Heading
        v-if="post.slug !== 'homepage'"
        class="page--title"
        typo="heading"
        :value="post.title.rendered"
      />
      <Gutenberg :data="post.wp_blocks" />
    </Wrapper>
  </main>
</template>

<script>
import data from '@/mixins/data';

import Heading from '@/components/typo/heading';
import Gutenberg from '@/components/blocks';

export default {
  name: 'Page',
  components: {
    Gutenberg,
    Heading,
  },
  mixins: [data],
};
</script>

<style lang="scss" scoped>
.page {
  padding-top: calc(var(--spacer-l) + var(--spacer-l));
  @include mq(m) {
    padding-top: var(--spacer-xl);
  }

  &--title {
    padding-bottom: var(--spacer-s);
    @include mq(m) {
    padding-bottom: calc(var(--spacer-l) * 2);
    }
  }
}
</style>
