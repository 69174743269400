<template>
  <main>
    <div
      v-if="post.type === 'project' && post.wp_featured_image"
      class="single--cover"
    >
      <Figure
        v-if="post.wp_featured_image"
        :data="{
          value: {
            src: post.wp_featured_image,
          },
          settings: {}
        }"
        theme="thumb"
      />
    </div>
    <div :class="post.type === 'project' && post.wp_featured_image ? 'single--content--no-padding' : 'single--content'">
      <Wrapper boxed>
        <div class="single--heading">
          <Heading
            typo="heading"
            :value="post.title.rendered"
          />
          <Icon name="arrow-down" />
        </div>

        <div class="single--intro">
          <div class="single--intro--table">
            <div class="single--intro--table--title">
              <div class="title typo--mono t-uppercase">
                Project
              </div>
              <div
                class="content typo--p"
                v-html="post.title.rendered"
              />
            </div>
            <div class="single--intro--table--year">
              <div class="title typo--mono t-uppercase">
                Year
              </div>
              <div
                class="content typo--p"
                v-html="post.acf.year"
              />
            </div>
            <div class="single--intro--table--meters">
              <div class="title typo--mono t-uppercase">
                M. Q.
              </div>
              <div
                class="content typo--p"
                v-html="post.acf.square_meters"
              />
            </div>
            <div class="single--intro--table--category">
              <div class="title typo--mono t-uppercase">
                Categoria
              </div>
              <div
                class="content typo--p"
                v-html="post.wp_taxonomies.categories[0].name"
              />
            </div>
            <div class="single--intro--table--cliente">
              <div class="title typo--mono t-uppercase">
                Client
              </div>
              <div
                class="content typo--p"
                v-html="post.acf.cliente"
              />
            </div>
          </div>
          <div
            class="single--intro--description"
            v-html="post.acf.abstract"
          />
        </div>
      </Wrapper>
      <Wrapper
        class="single--gutenberg"
        boxed
      >
        <Gutenberg :data="post.wp_blocks" />
        <div class="single--adjacent">
          <div>
            <Link
              v-if="post.adjacent.prev"
              :data="{ title: 'Prev Project', url: post.adjacent.prev }"
              theme="button--reverse"
            />
          </div>
          <div>
            <Link
              v-if="post.adjacent.next"
              :data="{ title: 'Next Project', url: post.adjacent.next }"
              theme="button"
            />
          </div>
        </div>
      </Wrapper>
    </div>
  </main>
</template>

<script>
import Gutenberg from '@/components/blocks';

import data from '@/mixins/data';
import Figure from '@/components/media/figure';
import Heading from '@/components/typo/heading';
import Icon from '@/components/ui/icon';
import Link from '@/components/typo/link.vue';

export default {
  name: 'Single',
  components: {
    Gutenberg,
    Figure,
    Heading,
    Icon,
    Link,
  },
  mixins: [data],
  mounted() {
    console.log(this.post);
  },
};
</script>

<style lang="scss" scoped>
.single {
  &--gutenberg {
    padding-top: var(--spacer-m);

    @include mq(m) {
      padding-top: var(--spacer-xl);
    }
  }

  &--cover {
    height: calc(var(--vh, 1vh) * 100);
  }

  &--content {
    padding-top: var(--spacer-xl);

    &--no-padding {
      // padding-top: var(--spacer-m);
    }
  }

  &--adjacent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: calc(var(--spacer-l)*2);
    padding-bottom: var(--spacer-l);
    gap: var(--spacer-m);

    &>* {
      &:empty {
        display: none;
      }

      margin: auto;

      @include mq(s) {
        margin: initial;
      }
    }
  }

  &--heading {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 121px 0;
  }

  &--intro {
    padding: 0 0 var(--spacer-m) 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacer-m);
    max-height: 400px;

    @include mq(m) {
      flex-direction: row;
    }

    &--description {
      white-space: pre-wrap;
      overflow: auto;
    }

    &--table {
      width: 100%;

      @include mq(m) {
        flex: 0 0 350px;
      }

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1px;
      background: var(--black);
      border: 1px solid;

      &>* {
        background: var(--white);
        padding: var(--spacer-m)
      }

      &--title,
      &--category,
      &--cliente {
        grid-column: span 2;
      }

      .title {
        font-weight: lighter;
      }

      .content {
        font-weight: bold;
      }
    }
  }
}
</style>
