<template>
  <div>
    <component
      :is="mergeProps.tag"
      :class="['title', `title--${mergeProps.typo}`, `title--style--${mergeProps.style}`, `typo--${mergeProps.typo}`]"
    >
      <span v-html="mergeProps.value" />
      <Icon
        v-if="mergeProps.style === 'full_height'"
        name="arrow-down"
        class="title--arrow"
      />
    </component>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Heading',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    value: {
      type: String,
      default: () => { },
    },
    typo: {
      type: String,
      default: () => { },
    },
    tag: {
      type: String,
      default: () => { },
    },
  },
  computed: {
    mergeProps() {
      return {
        typo: this.typo ? this.typo : this.data ? this.data.settings?.typo : 'default',
        style: this.data ? this.data.settings?.style : 'default',
        value: this.value || this.data.value?.heading,
        tag: this.tag ? this.tag : this.data && this.data.settings?.tag ? this.data.settings?.tag : 'div',
      };
    },
  },
  mounted() {
    console.log(this.mergeProps.value);
  },
};
</script>

<style lang="scss" scoped>
.title {
  &--arrow {
    padding-top: var(--spacer-l);
  }
  &--style {
    &--full_height {
      padding: var(--spacer-m) 0;

      @include mq(m) {
        padding: var(--spacer-xl) 297px;
      }
    }
  }
}
</style>
