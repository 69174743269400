<template>
  <figure
    ref="figure"
    :class="['figure', themeClass, alignment, disableRatio ? 'figure--native' : false]"
    :data-lazy="lazyload ? 'false' : null"
    :style="{
      '--image-w': image ? image.width : 0,
      '--image-h': image ? image.height : 0,
    }"
  >
    <div :class="['image--wrapper', maxHeight]">
      <img
        v-if="image"
        ref="image"
        :class="['image']"
        :data-src="lazyload ? src : null"
        :src="!lazyload ? src : ''"
        :alt="image.alt"
      >
      <div
        v-if="lazyload"
        class="placeholder"
      />
    </div>
    <Richtext
      v-if="caption"
      typo="caption"
      tag="figcaption"
      :value="caption"
    />
  </figure>
</template>

<script>
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Figure',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: () => { },
    },
    disableRatio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
    };
  },
  computed: {
    settings() {
      return this.data.settings;
    },
    image() {
      return this.currentImg === 'mobile'
        && this.data.settings.toggle
        && (this.data.value.image_mobile || this.data.value.src_mobile)
        ? this.data.value.image_mobile || this.data.value.src_mobile
        : this.data.value.image || this.data.value.src;
    },
    caption() {
      if (this.data.settings.caption) {
        return this.data.value.caption
          ? this.data.value.caption
          : this.image.caption;
      }
      return false;
    },
    themeClass() {
      return this.theme
        ? `figure--${this.theme}`
        : this.data.settings.theme
          ? `figure--${this.data.settings.theme}`
          : false;
    },
    alignment() {
      return this.data.settings.alignment && this.data.settings.size === 'max-height'
        ? `figure--${this.data.settings.alignment}`
        : undefined;
    },
    maxHeight() {
      return this.data.settings.size === 'max-height'
        ? 'figure--max-height'
        : undefined;
    },
    mobileThemeClass() {
      return this.settings && this.settings.mobile_view
        ? `figure--mobile--${this.settings.mobile_view}`
        : 'figure--mobile--default';
    },
    link() {
      return this.data.value.link;
    },
    url() {
      if (this.link) {
        if (this.link && this.link.url && typeof this.link.url === 'string') {
          return this.$relativeUrl(this.link.url);
        }
        return this.link.url;
      }
      return undefined;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    tag() {
      return !this.link ? 'div' : this.external ? 'a' : 'router-link';
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);

    if (!this.lazyload) {
      this.load();
    }
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      if (!this.image || !this.image.sizes) {
        return;
      }
      let src = this.image.sizes.l;

      const { sizes } = this.image;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$parent.$el.offsetWidth;
      const dimension = 'width';
      const max = wrapper * ratio;
      const ranges = {
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'l';

      this.$nextTick(() => {
        if (this.$mq.isMobile && this.data.settings.toggle) {
          this.currentImg = 'mobile';
        } else {
          this.currentImg = 'default';
        }
        src = this.image.sizes[size];

        if (this.$refs.figure.dataset.lazy === 'true') {
          this.$refs.image.src = src;
        }

        this.src = src;
        this.load();
      });
    },
    async load() {
      const img = this.$refs.image;

      if (this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.figure {
  margin: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  --width: var(--image-w);
  --height: var(--image-h);

  .image--wrapper {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &:not(.figure--native) {
    .image--wrapper {
      @include aspect-ratio(var(--width), var(--height));
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: var(--fit, fill);
    object-position: var(--origin, 50% 50%);
  }

  &--native {
    .image {
      position: relative;
      position: initial;
      height: auto;
    }
  }

  &--gallery--posts,
  &--thumb {
    height: 100%;
    width: 100%;

    .image--wrapper {
      height: 100%;
      width: 100%;
      max-height: initial;

      .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &--default {
    /* --width: 16;
      --height: 9;
      --fit: cover;
      --origin: bottom center; */
  }

  &.align--top {
    .image {
      object-position: 50% 0;
    }
  }

  &.align--center {
    .image {
      object-position: 50% 50%;
    }
  }

  &.align--bottom {
    .image {
      object-position: 50% 100%;
    }
  }

  &.figure-contain {
    .image {
      object-fit: contain;
    }
  }

  &--right {
    margin-left: auto;
    text-align: right;
  }

  &--left {
    margin-right: auto;
  }

  &--center {
    margin: auto;
  }

  &--max-height {
    max-height: calc(var(--vh, 1vh) * 100);
    width: max-content;
    max-width: 100%;
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s $ease-custom;
    will-change: opacity;

    background: var(--grey-d);
  }

  .image {
    visibility: hidden;

    &[src] {
      visibility: visible;
    }
  }

  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
  }
}
</style>
