var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{staticClass:"projects",attrs:{"boxed":""}},[_c('Heading',{staticClass:"projects--heading",attrs:{"typo":"heading","value":"Progetti"}}),_c('Archive',{attrs:{"data":{
        value: {
          post_type: 'project',
        },
        settings: {
          posts_per_page: '100',
          filters: [],
          offset: 0,
          order: 'asc',
        },
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }