<template>
  <Wrapper boxed>
    404
  </Wrapper>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
