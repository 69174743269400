<template>
  <div class="gutenberg">
    <component
      :is="components.get(parseBlockName(block.blockName))"
      v-for="(block, index) in data"
      :key="index"
      :data="block.blockName.includes('acf/') ? block.attrs.data : block"
    />
  </div>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import baseComponents from '@/assets/js/components';
import Group from '@/components/blocks/group';

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      parseBlockName,
    };
  },
  computed: {
    components() {
      const value = baseComponents;

      value.set('group', Group);

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.gutenberg {
  display: flex;
  flex-direction: column;

  &>*+* {
    margin-top: var(--spacer-m);

    @include mq(m) {
      gmargin-top: var(--spacer-xl);
    }
  }

}
</style>
