<template>
  <div
    ref="gallery"
    :class="`gallery gallery--${type} swiper-container`"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(item, index) in gallery"
        :key="index"
        class="item swiper-slide"
      >
        <Figure
          ref="item"
          :data="{
            value: {
              image: type === 'posts' ? item.wp_featured_image : item,
            },
            settings: {},
          }"
          :lazyload="false"
          :theme="`gallery--${type}`"
        />
      </div>
    </div>
    <!-- If we need navigation buttons -->
    <div
      v-show="type === 'posts'"
      class="swiper-btn-prev"
    >
      <Icon name="arrow-left" />
    </div>
    <div
      v-show="type === 'posts'"
      class="swiper-btn-next"
    >
      <Icon name="arrow-right" />
    </div>
    <div v-show="type === 'posts'">
      <Link
        class="gallery--more"
        :data="{
          url: '/projects',
          title: 'Scopri tutti i progetti',
        }"
        theme="button"
      />
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Autoplay } from 'swiper';

import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';
import Link from '@/components/typo/link';

Swiper.use([Navigation, Autoplay]);

export default {
  name: 'Gallery',
  components: {
    Figure,
    Icon,
    Link,
  },
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => { },
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      swiper: null,
      settings: {
        autoplay: {
          delay: 5000,
        },
        loop: true,
        navigation: this.type === 'posts'
          ? {
            nextEl: '.swiper-btn-next',
            prevEl: '.swiper-btn-prev',
          }
          : false,
        slidesPerView: this.type === 'posts' ? 1 : 'auto',
        spaceBetween: 10,
      },
    };
  },
  computed: {
    gallery() {
      if (this.type === 'posts') {
        return this.data;
      }
      return this.data.value.gallery;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.gallery.length > 0) {
        this.swiper = new Swiper(
          this.$refs.gallery,
          Object.assign(this.settings, this.extraSettings),
        );
      }
    });
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 25px solid transparent;
    border-bottom: 25px solid var(--white);
    border-right: 25px solid var(--white);
  }

  &--posts {
    height: 100%;
  }

  .swiper-slide {
    width: auto;
    height: auto;
  }

  .swiper-btn {
    cursor: pointer;
    position: absolute;
    height: 50px;
    width: 50px;
    background: var(--white);
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;

    &-next {
      @extend .swiper-btn;
      right: var(--spacer-s);

      @include mq(m) {
        right: var(--spacer-l);
      }
    }

    &-prev {
      @extend .swiper-btn;
      left: var(--spacer-s);

      @include mq(m) {
        left: var(--spacer-l);
      }
    }
  }

  &--more {
    position: absolute;
    bottom: var(--spacer-l);
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    max-width: 100%;
    overflow: hidden;
    :deep(a) {
      white-space: nowrap;
      justify-content: center;
      overflow: hidden;
    }

  }
}
</style>
