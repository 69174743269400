<template>
  <component
    :is="tag"
    :class="['richtext', `richtext--${typo}`]"
    v-html="value || data.innerHTML"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: 'div',
    },
    typo: {
      type: String,
      default: 'default',
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
.richtext {
  &--caption {
    @extend %typo--small;
    padding-top: var(--spacer-s)
  }
}
</style>
