<template>
  <div class="archive">
    <Heading
      v-if="data.value.title"
      :value="data.value.title"
    />
    <div class="archive--filter ">
      <div class="archive--filter--text">
        Seleziona la categoria di riferimento.
      </div>
      <div class="archive--filter--wrapper typo--mono t-uppercase">
        <div
          v-for="category in categories"
          :key="category.id"
          :class="`archive--category ${filter && filter === category.id ? 'archive--category--active' : ''}`"
          @click="setFilter(category.id)"
          v-html="category.name"
        />
      </div>
    </div>
    <Grid
      v-if="items.length > 0"
      :col="{ default: 12, m: 6 }"
      :row-gap="$mq.isMobile ? 'm' : 'l-m'"
    >
      <div
        v-for="(item, index) in computedItems"
        :key="'filter-' + filter + index"
        class="archive--item"
      >
        <component
          :is="thumbs.get('default')"
          :data="item"
        />
      </div>
    </Grid>

    <div
      v-show="items && data.settings.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Link
      v-if="data.value.link"
      :data="data.value.link"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';

import Thumb from '@/components/thumb';
import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';

const thumbs = new Map();
thumbs.set('default', Thumb);

export default {
  name: 'Archive',
  components: {
    Heading,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      request: null,
      items: [],
      offset: 0,
      placeholder: 'Loading',
      filter: null,
    };
  },
  computed: {
    ...mapGetters(['postType', 'categories']),
    computedItems() {
      return this.items.filter((item) => (this.filter ? item.categories.includes(this.filter) : true));
    },
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.settings.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      return items;
    },
    setRequest() {
      const type = this.postType(this.data.value.post_type).rest_base;
      const per_page = parseInt(this.data.settings.posts_per_page, 10);
      const order = this.data.settings.order ? 'desc' : 'asc';
      const orderby = this.data.settings.order_by;
      const { offset } = this;

      const filters = {};

      this.data.settings.filters.forEach((filter) => {
        const string = filter.split(':');
        // eslint-disable-next-line prefer-destructuring
        filters[string[0]] = parseInt(string[1], 10);
      });

      this.request = {
        type,
        params: {
          ...filters,
          per_page,
          offset,
          order,
          orderby,
          lang: this.$store.state.lang,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget('infinite-scroll', this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      const { height, top } = this.$el.getBoundingClientRect();
      this.offset += parseInt(this.data.settings.posts_per_page, 10);
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if ((window.pageYOffset + window.innerHeight) >= height + top) {
          this.requestOffset();
        }
      });
    },
    setFilter(categoryId) {
      if (this.filter && this.filter === categoryId) {
        this.filter = null;
      } else {
        this.filter = categoryId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;

  &--category {
    cursor: pointer;
    user-select: none;

    &--active {
      color: var(--grey);
    }
  }

  &--item {
    position: relative;
    padding-bottom: var(--spacer-l);

    @include mq(m) {
      padding-bottom: initial;
    }
  }

  &--filter {
    padding-bottom: var(--spacer-xl);

    &--text {
      padding-bottom: var(--spacer-s);
    }

    &--wrapper {
      display: flex;
      flex-direction: column;

      @include mq(m) {
        flex-direction: row;
      }

      border: 1px solid;
      gap: 1px;
      background: var(--black);

      &>* {
        flex: 1;
        padding: var(--spacer-m) var(--spacer-l);

        background: var(--white);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }
}

.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 50vh;
}
</style>
