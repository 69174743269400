<template>
  <div :class="['video', computedTheme ? `video--${computedTheme}` : false]">
    <div
      v-if="source === 'url'"
      ref="player"
      class="plyr"
      v-html="data.value.url"
    />

    <video
      v-if="source === 'file'"
      ref="player"
      class="plyr"
      :data-poster="
        data.settings.poster && data.value.poster
          ? data.value.poster.sizes.l
          : null
      "
      playsinline
    >
      <source
        :src="data.value.src.url"
        type="video/mp4"
      >
    </video>

    <Richtext
      v-if="data.value.caption"
      :data="{ innerHTML: data.value.caption }"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Richtext from '@/components/typo/richtext';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      plyr: null,
      src: null,
      hls: null,
      currentVideo: 'default',
      source: 'url',
    };
  },
  computed: {
    video() {
      let value = false;

      if (
        this.currentVideo === 'mobile'
          && this.data.settings.mobile_toggle
          && this.data.value.src_mobile
      ) {
        if (this.data.settings.mobile.source === 'url') {
          value = this.data.value.url_mobile;
        } else value = this.data.value.src_mobile.url;
      } else if (this.data.settings.source === 'url') {
        value = this.data.value.url;
      } else {
        value = this.data.value.src.url;
      }
      return value;
    },
    computedTheme() {
      let value = false;
      if (this.theme) {
        value = this.theme;
      } else if (this.data.settings.style) {
        value = this.data.settings.style;
      }
      return value;
    },
  },
  watch: {
    src(value) {
      if (value) {
        this.$nextTick(() => {
          const { player } = this.$refs;

          this.plyr = new Plyr(player, {
            autoplay: true,
            controls: ['play', 'progress', 'mute'],
            /* eslint-disable-next-line */
              // iconUrl: require("@/assets/svg/icons/plyr.svg"),
            fullscreen: false,
            storage: {
              enabled: false,
            },
            loop: {
              active: true,
            },
            muted: true,
          });
        });
      }
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      let src = this.video;

      if (
        this.$mq.isMobile
          && this.data.settings.mobile_toggle
          && this.data.value.src_mobile
      ) {
        this.currentVideo = 'mobile';

        if (this.data.settings.mobile.source === 'url') {
          this.source = 'url';
        } else this.source = 'file';
      } else {
        this.currentVideo = 'default';

        if (this.data.settings.source === 'url') {
          this.source = 'url';
        } else this.source = 'file';
      }

      src = this.video;
      this.src = src;
    },
  },
};
</script>

<style lang="scss" scoped>
  .video {
    position: relative;
    width: 100%;
  }
</style>

<style lang="scss">
  .plyr {
    --plyr-color-main: $color-white;
    // --plyr-control-icon-size: 21px;
    --plyr-range-track-height: 0px;
    --plyr-range-thumb-height: 0px;
    --plyr-range-thumb-shadow: none;

    .plyr__progress__buffer {
      border-radius: 0px;
    }

    .plyr__progress input[type="range"] {
      border-radius: 0px;
    }

    .plyr__tooltip {
      display: none;
    }

    .plyr__volume {
      width: auto;
      max-width: auto;
      min-width: auto;
    }
  }
</style>
