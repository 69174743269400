<template>
  <Flex
    nowrap
    :class="[
      'link',
      themeClass,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'link--disabled' : null,
    ]"
  >
    <component
      :is="tag"
      class="link--content"
      :href="external ? url : undefined"
      :to="!external ? url : undefined"
      :target="link.target"
      @[event]="data.fn"
      v-html="title"
    />
    <component
      :is="tag"
      v-show="themeClass === 'link--button' || themeClass === 'link--button--reverse' || themeClass === 'link--button--thumb'"
      :href="external ? url : undefined"
      :to="!external ? url : undefined"
      :target="link.target"
      class="link--button--figure"
      @[event]="data.fn"
    >
      <Icon name="go" />
    </component>
  </Flex>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Link',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    event() {
      return this.data.fn ? 'click' : null;
    },
    tag() {
      return this.data.fn ? 'button' : this.external ? 'a' : 'router-link';
    },
    link() {
      return this.data.value ? this.data.value.link : this.data;
    },
    title() {
      return this.link.title;
    },
    url() {
      if (
        this.link.url
        && typeof this.link.url === 'string'
      ) {
        return this.$relativeUrl(this.link.url);
      }
      return this.link.url;
    },
    external() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('http')
      );
    },
    themeClass() {
      return this.data.settings && this.data.settings.theme ? `link--${this.data.settings.theme}` : this.theme ? `link--${this.theme}` : false;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;

  &.router-link-active {
    // style
  }
}

.link--disabled {
  opacity: 0.5;
  pointer-events: none;

  * {
    pointer-events: none !important;
  }
}

.link {
  &--button {
    @extend %typo--mono;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: var(--black);
    background: var(--white);
    text-align: center;
    border: 1px solid;
    width: max-content;
    margin: auto;

    & .link--content {
      padding: var(--spacer-s) var(--spacer-l);
      border-right: 1px solid
    }

    &--reverse {
      @extend .link--button;
      flex-direction: row-reverse;

      .link--content {
        padding: var(--spacer-s) var(--spacer-l);
        border-left: 1px solid;
        border-right: none
      }

      .link--button--figure {
        transform: rotate(180deg);
      }

    }
  }

  &>* {
    height: 100%;
    padding: var(--spacer-s) var(--spacer-m);
    display: flex;
    align-items: center;
  }
}
</style>
