<template>
  <!-- <header v-show="!$route.meta.hideHeader"> -->
  <Spacer
    class="header"
    tag="header"
    :all="$mq.isMobile ? 'm' : 'l'"
  >
    <Flex
      class="header--wrapper"
      justify="space-between"
    >
      <router-link
        to="/"
        class="header--logo"
      >
        <inline-svg :src="options.website_logo.url" />
      </router-link>
      <Menu v-if="activeMenu" />
      <div
        class="header--menu"
        to="/"
        @click="toggleMenu"
      >
        <Icon
          v-if="!activeMenu"
          name="menu"
          size="s"
          :fn="toggleMenu"
        />
        <div
          v-else
          class="header--menu--close"
        />
      </div>
    </Flex>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';
import Menu from '@/components/ui/menu';

export default {
  name: 'Header',
  components: {
    Menu,
    Icon,
  },
  data() {
    return {
      activeMenu: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  watch: {
    $route: {
      handler() {
        this.activeMenu = false;
      },
    },
  },
  methods: {
    toggleMenu() {
      console.log('active');
      this.activeMenu = !this.activeMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  pointer-events: none;

  &--logo {
    display: flex;
    width: 150px;
    justify-content: center;
    align-items: center;
    padding: var(--spacer-s);

    svg {
      height: var(--spacer-m)
    }
  }

  &--menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacer-s);
    width: var(--spacer-l);

    &--close {
      &:before,
      &:after {
        position: absolute;
        top: var(--spacer-s);
        left: var(--spacer-m);
        content: ' ';
        height: 30px;
        width: 2px;
        background-color: var(--black);
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &--wrapper {
    border: solid 1px;
    background: var(--white);
    height: 50px;
    pointer-events: initial;
    &>*:not(:last-child) {
      border-right: solid 1px;
    }

    &>*:not(:first-child) {
      border-left: solid 1px;
    }
  }
}
</style>
