<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb"
  >
    <Figure
      v-if="data.wp_featured_image"
      :data="{
        value: {
          src: data.wp_featured_image,
        },
        settings: {}
      }"
      theme="thumb"
    />
    <Flex
      class="thumb--heading"
      justify="space-between"
      align="center"
      nowrap
    >
      <div class="thumb--info">
        <div class="thumb--categories">
          <span
            v-for="category in categories"
            :key="category.id"
            v-html="category.name"
          />
        </div>
        <div
          class="thumb--title"
          v-html="data.title.rendered"
        />
      </div>
      <div class="thumb--arrow">
        <Icon name="go" />
      </div>
    </Flex>

    <div class="thumb--corner" />
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categories() {
      return this.data.wp_taxonomies.categories;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;
  overflow: hidden;
  @include aspect-ratio(16, 9);

  &--heading {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--white);
    border: solid 1px;
    // height: 75px;
  }

  &--info {
    padding: var(--spacer-m) var(--spacer-l);
    border-right: 1px solid;
    flex: 1;
    overflow: hidden
  }

  &--categories {
    @extend %typo--mono;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    & > *:not(:last-child) {
      &::after {
        content: ',';
        margin-right: 3px;
      }
    }
  }

  &--title {
    margin-top: var(--spacer-xs);
    font-weight: 700;
  }

  &--arrow {
    display: flex;
    align-items: center;
    padding: var(--spacer-m) var(--spacer-l);
  }

  &--corner {
    position: absolute;
    left: -1px;
    top: -1px;
    z-index: 2;
    width: 0;
    height: 0;
    border-top: 25px solid var(--white);
    border-left: 25px solid var(--white);
    border-bottom: 25px solid transparent;
    border-right: 25px solid transparent;
  }
}
</style>
