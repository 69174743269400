var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"gallery",class:`gallery gallery--${_vm.type} swiper-container`},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.gallery),function(item,index){return _c('div',{key:index,staticClass:"item swiper-slide"},[_c('Figure',{ref:"item",refInFor:true,attrs:{"data":{
            value: {
              image: _vm.type === 'posts' ? item.wp_featured_image : item,
            },
            settings: {},
          },"lazyload":false,"theme":`gallery--${_vm.type}`}})],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'posts'),expression:"type === 'posts'"}],staticClass:"swiper-btn-prev"},[_c('Icon',{attrs:{"name":"arrow-left"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'posts'),expression:"type === 'posts'"}],staticClass:"swiper-btn-next"},[_c('Icon',{attrs:{"name":"arrow-right"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'posts'),expression:"type === 'posts'"}]},[_c('Link',{staticClass:"gallery--more",attrs:{"data":{
          url: '/projects',
          title: 'Scopri tutti i progetti',
        },"theme":"button"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }