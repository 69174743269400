<template>
  <Spacer
    class="page-footer typo--small"
    tag="footer"
    :all="$mq.isMobile ? 'm' : 'l'"
  >
    <div class="footer--wrapper">
      <router-link
        to="/"
        class="footer--logo"
      >
        <inline-svg :src="options.website_logo.url" />
      </router-link>
      <div class="footer--name t-uppercase">
        <router-link to="/">
          {{ $store.state.wp.site.name }}
        </router-link>
      </div>
      <div
        class="footer--address"
        v-html="options.address"
      />
      <div
        v-if="options.social.length > 0"
        class="footer--social"
      >
        <a
          v-for="(social, i) in options.social"
          :key="i"
          :href="social.link"
        >{{ social.titolo }}</a>
      </div>
      <!-- <Menu /> -->
    </div>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['options', 'currentPost']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  &--logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacer-s);

    @include mq(m) {
      width: 150px;
    }

    svg {
      height: var(--spacer-m)
    }
  }

  &--wrapper {
    display: grid;
    background: var(--black);
    gap: 1px;
    grid-template-columns: repeat(2, 1fr);

    @include mq(m) {
      display: flex;
      flex-wrap: nowrap;
    }

    border: solid 1px;

    & > * {
      background: var(--white);
      padding: var(--spacer-m);
      @include mq(m) {
        padding: var(--spacer-s);
      }
    }
  }

  &--name {
    display: flex;
    align-items: center;
    padding: var(--spacer-m);
    @include mq(m) {
      padding: var(--spacer-s) var(--spacer-l);
      min-width: max-content;
    }

    font-weight: bold;
    letter-spacing: 0.08em;

  }

  &--address,
  &--social {
    flex: 1;
    word-break: break-all;
    a {
      display: block;
    }
  }
}
</style>
