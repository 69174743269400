<template>
  <nav class="menu">
    <div
      v-for="item in navigation"
      :key="item.id"
      :class="[...item.css, 'menu--item']"
    >
      <Link
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
        }"
        class="menu--item--link"
      />
      <div class="menu--item--active" />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  name: 'Menu',
  components: {
    Link,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  background: var(--white);
  position: absolute;
  top: calc(var(--spacer-m) + var(--spacer-l));
  right: var(--spacer-m);
  left: var(--spacer-m);

  @include mq(m) {
    top: initial;
    right: var(--spacer-l);
    left: initial;
    width: auto;
    min-width: 350px;
  }

  &--item {
    display: flex;
    border-bottom: 1px solid;
    height: calc(var(--spacer-l) - 1px);
    text-transform: uppercase;
    text-align: center;

    &--active {
      border-left: 1px solid;
      height: 100%;
      width: var(--spacer-l);
    }

    &--link {
      width: calc(100% - 50px);
      display: flex;
      justify-content: center;
    }
  }
}
</style>
