<template>
  <div class="contact">
    <div class="contact--initial">
      <div class="contact--wrapper">
        <div class="titles">
          <div class="subtitle typo--mono t-uppercase">
            Contacts
          </div>
          <div class="title">
            GAM STUDIO
          </div>
        </div>
        <div
          class="body"
          v-html="options.address"
        />
      </div>
    </div>
    <div class="contact--initial">
      <div class="contact--social">
        <div
          v-for="(social, i) in options.social"
          :key="i"
          class="social"
        >
          <div
            class="contact--social--title typo--mono t-uppercase"
            v-html="social.titolo"
          />
          <a
            :href="social.link"
            v-html="social.username"
          />
        </div>
      </div>
    </div>
    <div
      v-for="(additional, i) in data.additional_info"
      :key="i"
      class="contact--additional"
    >
      <div class="contact--wrapper">
        <div class="titles">
          <div
            class="subtitle typo--mono t-uppercase"
            v-html="additional.subtitle"
          />
          <div
            class="title"
            v-html="additional.title"
          />
        </div>
        <div
          class="body"
          v-html="additional.text"
        />
      </div>
      <div class="contact--arrow">
        <Icon name="arrow-right" />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';
import { mapGetters } from 'vuex';

export default {
  name: 'Contact',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['options']),
  },
  mounted() {
    console.log(this.options);
  },
};
</script>

<style lang="scss" scoped>
.contact {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  overflow: hidden;

  @include mq(m) {
    grid-template-columns: 1fr 1fr;
  }

  gap: 1px;

  background: var(--black);
  border: 1px solid;

  &>* {
    background: var(--white);
  }

  &--arrow {
    display: none;

    @include mq(m) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: var(--spacer-l);
    }
  }

  &--additional {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(m) {
      grid-template-columns: 1fr 1fr;
      grid-column: span 2;
    }
  }

  &--social {
    display: flex;
    padding: var(--spacer-l);
    justify-content: space-between;
    font-weight: bold;
    word-break: break-all;
    flex-direction: column;
    gap: var(--spacer-s);

    @include mq(m) {
      flex-direction: row;
      gap: initial
    }

    &--title {
      font-weight: lighter;
    }
  }

  &--wrapper {
    display: grid;
    grid-template-columns: 1fr;
    padding: var(--spacer-l);
    gap: var(--spacer-s);

    @include mq(m) {
      grid-template-columns: 1fr 1fr;
      gap: initial;
    }
  }

  .subtitle {
    font-weight: lighter;
  }

  .title {
    font-weight: bold;
  }
}
</style>
