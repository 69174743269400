<template>
  <Wrapper
    boxed
    class="projects"
  >
    <Heading
      class="projects--heading"
      typo="heading"
      value="Progetti"
    />
    <Archive
      :data="{
        value: {
          post_type: 'project',
        },
        settings: {
          posts_per_page: '100',
          filters: [],
          offset: 0,
          order: 'asc',
        },
      }"
    />
  </Wrapper>
</template>

<script>
import Archive from '@/components/blocks/archive';
import Heading from '@/components/typo/heading';

export default {
  name: 'ProjectsView',
  components: {
    Archive,
    Heading,
  },
};
</script>

<style lang="scss" scoped>
.projects {
  padding-top: var(--spacer-xl);
  padding-bottom: var(--spacer-xl);
  &--heading{
    padding-bottom: var(--spacer-xl)
  }
}
</style>
